// @flow
import * as React from "react";

type Props = {
  type: "banner" | "alert",
  messages: ?Array<string>,
};

function BannerBar(props: Props) {
  return (
    <>
      {props.messages && props.messages.length ? (
        <div data-testid={`af-${props.type}bar`} className={`af-${props.type}bar`}>
          <div className="container">
            {props.messages.map((message, i) => {
              return (
                <div key={`banner-${i}`} className="row align-items-center">
                  <div className="col-12">{message}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default BannerBar;
