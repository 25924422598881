// @flow
import * as React from "react";

type Props = {
  text: string,
  href: string,
  icon?: string,
  className?: string,
};

function MenuItem(props: Props) {
  return (
    <a data-testid="af-menuitem" className={`af-menuitem${props.className ? " " + props.className : ""}`} href={props.href}>
      {props.icon ? <span className={`icon-${props.icon}`} /> : null}
      <span className={"af-menuitem-text"}>{props.text}</span>
    </a>
  );
}

export default MenuItem;
