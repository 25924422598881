// @flow
import * as React from "react";

type Props = {
  partnerId?: ?string,
};

function CopyrightBar(props: Props) {
  const { partnerId } = props;

  const partnerAddendum = partnerId ? partnerId + "/" : "";
  const propertyUrl = `https://www.connectbyamfam.com/${partnerAddendum}legal/american-family-connect-property-and-casualty-ins-co/`;
  const insUrl = `https://www.connectbyamfam.com/${partnerAddendum}legal/american-family-connect-ins-co/`;
  const statesUrl = `https://www.connectbyamfam.com/${partnerAddendum}legal/state-list/`;

  const date = new Date();
  const year = date.getFullYear();

  return (
    <div data-testid="af-copyright-bar" className="af-copyright-bar">
      <div className="container px-0">
        <div>© {year} American Family Connect Property and Casualty Insurance Company, Inc. All rights reserved.</div>
        <div className="mt-2">
          CONNECT, powered by American Family Insurance is the brand name of{" "}
          <a href={propertyUrl} target={"_blank"} rel="noopener noreferrer">
            American Family Connect Property and Casualty Insurance Company
          </a>{" "}
          and{" "}
          <a href={insUrl} target={"_blank"} rel="noopener noreferrer">
            American Family Connect Insurance Company
          </a>
          , De Pere, WI, both of which have sole financial responsibility for their own products. Insurance is underwritten by either company, depending on the state. The American Family Connect
          Insurance Agency, Inc., places policies with related and unrelated insurers. Each insurer is solely responsible for the claims on its policies and pays the Agency for policies sold. Both
          underwriting companies and the Agency are subsidiaries of American Family Insurance Mutual Holding Company.
        </div>
        {partnerId === "costco" ? (
          <div className="mt-2">
            Costco Wholesale, Costco Insurance Agency, Inc. and its subsidiaries do not underwrite insurance, pay claims or guarantee any of the products or services offered by CONNECT, powered by
            American Family Insurance. Costco Insurance Agency, Inc. is not licensed in New York state. Insurance products are not obtained through the Costco Insurance Agency in New York.
          </div>
        ) : null}
        <div className="mt-2">
          Insurance availability, discounts and savings may vary by region or{" "}
          <a href={statesUrl} target={"_blank"} rel="noopener noreferrer">
            state
          </a>{" "}
          in accordance with state filings and applicable law. Certain restrictions and limitations apply.
        </div>
        <div className="mt-2">
          We provide this information to help you understand insurance. Any coverage is subject to the terms of your policy. Please refer to your policy and declaration page for complete coverage
          details.
        </div>
        {partnerId === "advisor" ? <div className="mt-2">CONNECT is not affiliated with Ameriprise Financial, Inc.</div> : null}
      </div>
    </div>
  );
}

export default CopyrightBar;
