//@flow
import * as React from "react";
import { withRouter } from "react-router-dom";

import * as Yup from "yup";

import Form from "../controls/form/form";
import API from "../services/api";

type Props = {
  location: Object, // location is injected into props by the HOC withRouter
  history: Object, // history is injected into props by the HOC withRouter
};

/**
 * Form for submitting a CCPA request.
 */
function CcpaForm(props: Props) {
  return (
    <React.Fragment>
      <div className="text-left mb-2">
        <h1>Personal Information Privacy Request</h1>
      </div>

      <div className="mb-2">We would like to honor your request to the best of our ability. In order to do so and to verify your identification, please provide the following information.</div>
      <Form
        data-testid="af-ccpaform"
        fields={[
          {
            name: "TypeOfRequest",
            label: "What type of request is this?",
            type: "checkboxlist",
            options: [
              { text: "Do not sell my personal information. (Please note: We do not sell personal information.)", value: "DoNotSellInd" },
              { text: "Disclose my personal information and your third-party data sharing.", value: "DiscloseInd" },
              { text: "Delete my personal information.", value: "DeleteInd" },
              { text: "Correct my personal information.", value: "CorrectPiiInd" },
              { text: "Limit the use of sensitive personal information.", value: "LimitPiiInd" },
            ],
            validation: Yup.array().required("Please select at least one."),
          },
          {
            name: "RequestorInformation",
            type: "group",
            groupStyle: "grid",
            groupColumns: 2,
            fields: [
              {
                name: "FirstName",
                label: "First Name",
                type: "string",
                validation: Yup.string().required("Enter your first name."),
              },
              {
                name: "LastName",
                label: "Last Name",
                type: "string",
                validation: Yup.string().required("Enter your last name."),
              },
            ],
          },
          {
            name: "EmailAddress",
            label: "Email Address",
            type: "string",
            validation: Yup.string().email("Invalid email address. Please try again.").required("Enter your email address."),
          },
          {
            name: "StateCode",
            label: "Are you a resident of California?",
            type: "radiobuttonlist",
            inline: true,
            options: [
              { text: "Yes", value: "CA" },
              { text: "No", value: "false" }, // not certain if this is correct, but the old HomeOnline implementation did this
            ],
            validation: Yup.string()
              .required("Please select one.")
              .test("StateCode", "Please note that we are only processing requests for residents of the state of California at this time.", (value) => {
                if (value === "CA") {
                  return true;
                } else {
                  return false;
                }
              }),
          },
          {
            name: "SelfReportInd",
            label: "Are you making this request for yourself?",
            type: "radiobuttonlist",
            inline: true,
            options: [
              { text: "Yes", value: true },
              { text: "No", value: false },
            ],
            validation: Yup.boolean().required("Please select one."),
          },
          {
            name: "GuardianshipInd",
            label: "Are you a making this request on behalf of a minor or incapacitated individual?",
            type: "radiobuttonlist",
            inline: true,
            options: [
              { text: "Yes", value: true },
              { text: "No", value: false },
            ],
            visibilityCheck: (values) => {
              return values["SelfReportInd"] === "false" ? true : false;
            },
            validation: Yup.boolean().when("SelfReportInd", { is: false, then: Yup.boolean().required("Please select one."), otherwise: Yup.boolean().notRequired() }),
          },
          {
            name: "RelationshipCode",
            label: "What is your relationship with CONNECT Powered by American Family Insurance?",
            type: "select",
            options: [
              { text: "Policyholder", value: "1" },
              { text: "Former Policyholder", value: "2" },
              { text: "Quote Recipient", value: "3" },
              { text: "Claimant", value: "4" },
              { text: "Employee", value: "5" },
              { text: "Contractor", value: "6" },
              { text: "Vendor", value: "7" },
              { text: "Website Visitor", value: "8" },
            ],
            initialValue: 1,
            validation: Yup.number().required("Please select a relationship."),
          },
          {
            name: "PhoneNumber",
            label: "Phone Number",
            type: "tel",
            placeholder: "(xxx) xxx-xxxx",
            validation: Yup.string().required("You must enter a phone number."), // Note that the back-end API does not require a phone number, but we are requiring it here in the front-end form.
          },
          {
            name: "PolicyNumber",
            type: "string",
            label: "Policy Number (optional)",
            validation: Yup.string(),
          },
          {
            name: "Disclaimer",
            type: "custom",
            customRenderer: () => {
              return (
                <React.Fragment>
                  <hr />
                  <div className={"pt-1 pb-3"}>
                    The information that you are submitting as part of this request will be used solely to facilitate our response and will not be shared, sold, or disclosed. We will confirm receipt
                    of this information after you have submitted it for our review. In order to complete some requests, further correspondence may be required. For general information about our
                    privacy practices, see{" "}
                    <a href={"https://connectbyamfam.com/legal/privacy"} target={"_blank"} rel={"noopener noreferrer"}>
                      Our Privacy Notices
                    </a>
                    .
                  </div>
                </React.Fragment>
              );
            },
          },
        ]}
        onSubmit={(values) => {
          return new Promise((resolve) => {
            // We've grouped the requests types in our form so that we can
            // validate that at least one was selected. The privacy API
            // expects these to be separate boolean properties however, so
            // we need to scrub our values for submission to the API.
            const scrubbed = {};
            Object.keys(values).forEach((key) => {
              const value = values[key];
              if (value) {
                if (key === "TypeOfRequest") {
                  value.forEach((typeOfRequest) => {
                    scrubbed[typeOfRequest] = "true";
                  });
                } else {
                  scrubbed[key] = value;
                }
              }
            });

            API.post("Api.Privacy.Process/privacy", scrubbed).then((result) => {
              if (result.success) {
                setTimeout(() => {
                  props.history.push({ pathname: "/privacysuccess" });
                }, 500); // Defer the redirect to allow resolution to shake out.
              }
              resolve(result);
            });
          });
        }}
      />
    </React.Fragment>
  );
}

export default withRouter(CcpaForm);
