// @flow
import * as React from "react";

import * as Yup from "yup";

import Form from "../form/form";

type Props = {}; // Placeholder for future props, if we have any.

const baseAutoUrl = process.env.REACT_APP_AUTO_URL ? process.env.REACT_APP_AUTO_URL : "";
const baseHomeUrl = process.env.REACT_APP_HOME_URL ? process.env.REACT_APP_HOME_URL : "";
const autoUrl = `${baseAutoUrl}retrieveyourquote?request_type=un_auth_PreSSOWelcome&PartnerID=&RefID=&zip=`;
const homeUrl = `${baseHomeUrl}retrieveyourquote?PartnerID=&RefID=&zip=`;

const fields = [
  {
    name: "zip",
    type: "zip",
    label: "ZIP Code",
    floatingLabel: true,
    maxlength: 5,
    size: 12,
    validation: Yup.string()
      .required("You must enter a ZIP code.")
      .matches(/^[0-9]{5}$/, "You must enter a valid 5-digit ZIP code."),
  },
  {
    name: "type",
    type: "select",
    label: "Type of Insurance",
    floatingLabel: true,
    initialValue: "auto",
    options: [
      { text: "Auto", value: "auto" },
      { text: "Condo", value: "condo" },
      { text: "Home", value: "home" },
      { text: "Renters", value: "renters" },
    ],
    validation: Yup.string().required("You must select a type of insurance."),
  },
];

function QuoteBar(props: Props) {
  return (
    <div data-testid="af-quote-bar" className="af-quote-bar">
      <div className="container af-quote-form">
        <div className="row justify-content-center">
          <h4 className={"mb-3"}>Get an Insurance Quote</h4>
        </div>
        <div className="row justify-content-center mt-3 mx-3 mx-sm-0 mb-3 mb-sm-2">
          <Form
            data-testid="af-quote-form"
            fields={fields}
            inline={true}
            submitText={"Start Quoting"}
            onSubmit={(values) => {
              return new Promise((resolve) => {
                const response = { success: true, message: "" };
                const zip = values.zip ? values.zip : "";
                if (values.type) {
                  switch (values.type) {
                    case "auto": {
                      const redirect = `${baseAutoUrl}auto?zip=${zip}#PolicyholderDetails`;
                      window.location.href = redirect;
                      break;
                    }
                    case "condo": {
                      const redirect = `${baseHomeUrl}homesite`;
                      window.location.href = redirect;
                      break;
                    }
                    case "home": {
                      const redirect = `${baseHomeUrl}residenceaddress?zip=${zip}&ho_prod=H`;
                      window.location.href = redirect;
                      break;
                    }
                    case "renters": {
                      const redirect = `${baseHomeUrl}residenceaddress?zip=${zip}&ho_prod=R`;
                      window.location.href = redirect;
                      break;
                    }
                    default: {
                      response.success = false;
                      response.message = "Unknown insurance type.";
                    }
                  }
                } else {
                  response.success = false;
                  response.message = "No insurance type specified.";
                }

                resolve(response);
              });
            }}
          />
        </div>
        <div className="row justify-content-center mt-3 af-retrieve-bar">
          Retrieve Saved Quote:
          <ul className="in-line narrow piped">
            <li>
              <a href={autoUrl}>Auto</a>
            </li>
            <li>
              <a href={homeUrl}>Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default QuoteBar;
