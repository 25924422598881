// @flow
import * as React from "react";

type Props = {
  partnerId?: ?string,
  hideSocial?: boolean,
};

function AboutBar(props: Props) {
  const { partnerId, hideSocial } = props;

  const partnerAddendum = partnerId ? partnerId + "/" : "";
  const baseAahUrl = process.env.REACT_APP_AAH_URL ? process.env.REACT_APP_AAH_URL + partnerAddendum : "";

  const privacyUrl = `${baseAahUrl}legal/privacy/`;
  const securityUrl = `${baseAahUrl}legal/security/`;
  const termsUrl = `${baseAahUrl}legal/terms-of-use/`;

  const facedbookAddendum = partnerId === "costco" ? "Costco." : "";
  const twitterPage = partnerId === "costco" ? "CostcoAutoHome" : "CONNECTbyAmFam";

  const facebookUrl = `https://www.facebook.com/${facedbookAddendum}CONNECTbyAmFam`;
  const twitterUrl = `https://www.twitter.com/${twitterPage}`;
  const youtubeUrl = "https://www.youtube.com/c/CONNECTpoweredbyAmericanFamilyInsurance";
  const linkedInUrl = "https://www.linkedin.com/company/connectbyamfam";
  const instagramUrl = "https://www.instagram.com/connectbyamfam/";

  return (
    <div data-testid="af-about-bar" className="af-about-bar">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-8 align-self-center text-center text-md-left md-margin-left-0 px-1 px-md-0 order-12 order-md-1">
            <ul className="flex-list in-line p-0">
              <li>
                <h4>
                  <a href={privacyUrl}>Privacy</a>
                </h4>
              </li>
              <li>
                <h4>
                  <a href={securityUrl}>Security</a>
                </h4>
              </li>
              <li>
                <h4>
                  <a href={termsUrl}>Terms of Use</a>
                </h4>
              </li>
              <li>
                <h4>
                  <span className={"evidon-notice-link"} />
                </h4>
              </li>
            </ul>
          </div>
          {hideSocial ? null : (
            <ul className="af-about-bar-social in-line noshrink text-center text-md-right col-12 col-md-4 order-1 order-md-12 md-margin-left-30">
              <li>
                <a aria-label="facebook" href={facebookUrl} target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="icon-facebook" />
                </a>
              </li>
              <li>
                <a aria-label="twitter" href={twitterUrl} target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="icon-twitter" />
                </a>
              </li>
              <li>
                <a aria-label="youtube" href={youtubeUrl} target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="icon-youtube" />
                </a>
              </li>
              <li>
                <a aria-label="linkedin" href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="icon-linkedin" />
                </a>
              </li>
              <li>
                <a aria-label="instagram" href={instagramUrl} target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="icon-instagram" />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutBar;
