//@flow
import * as React from "react";

function Maintenance() {
  return (
    <div className="container" data-testid="af-maintenance">
      <div className="row">
        <h1>We’re sorry</h1>
        <div className="mt-2">
          We are currently undergoing system maintenance. Please try again later. If you would like further assistance return to our home page or contact us at one of the numbers listed below during
          normal business hours.
        </div>
        <div className="mt-2 mb-3">We apologize for any inconvenience this may have caused.</div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 m-0 p-0 splash-column">
          <div className="splash-info-head">
            <h3>Client Service</h3>
          </div>
          <div className="p-3">
            <div className="pb-2">
              <strong>Phone:</strong>
              <a className="pl-1" href="tel:800.535.2001">
                800.535.2001
              </a>
            </div>
            <div className="pb-2">
              <i>Hours (Central time)</i>
            </div>
            <div className="pb-2">
              <strong>Monday - Friday:</strong> 7 a.m. to 10 p.m.
            </div>
            <div className="pb-2">
              <strong>Saturday:</strong> 8:30 a.m. to 7 p.m.
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 m-0 p-0 splash-column">
          <div className="splash-info-head">
            <h3>Claims</h3>
          </div>
          <div className="p-3">
            <div className="pb-2">
              <strong>Phone:</strong>
              <a className="pl-1" href="tel:800.872.5246">
                800.872.5246
              </a>
            </div>
            <div className="pb-2">
              <i>Hours</i>
            </div>
            <div>24 hours a day, 7 days a week</div>
          </div>
        </div>

        <div className="col-12 col-md-4 m-0 p-0 splash-column">
          <div className="splash-info-head">
            <h3>Sales</h3>
          </div>
          <div className="p-3">
            <div className="pb-2">
              <strong>Phone:</strong>
              <a className="pl-1" href="tel:888.239.9953">
                888.239.9953
              </a>
            </div>
            <div className="pb-2">
              <i>Hours (Central time)</i>
            </div>
            <div className="pb-2">
              <strong>Monday - Friday:</strong> 7 a.m. to 10 p.m.
            </div>
            <div className="pb-2">
              <strong>Saturday:</strong> 8:30 a.m. to 7 p.m.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
