//@flow
import * as React from "react";

import LogoBar from "./logoBar/logoBar";
import MenuBar from "./menuBar/menuBar";
import BannerBar from "./bannerBar/bannerBar";

export type Banners = {
  alerts?: ?Array<string>,
  messages?: ?Array<string>,
};

type Props = {
  partnerId?: ?string,
  includePhoneNumber?: boolean,
  hideMenu?: boolean,
  banners?: Banners,
};

function Header(props: Props) {
  const { partnerId, includePhoneNumber, hideMenu, banners } = props;

  const partnerAddendum = partnerId ? partnerId + "/" : "";
  const baseAahUrl = process.env.REACT_APP_AAH_URL ? process.env.REACT_APP_AAH_URL + partnerAddendum : "";
  const queryStr = window.location.search ? window.location.search : "";
  const menuItems = [
    { text: "Insurance Choices", href: `${baseAahUrl}insurance-choices/${queryStr}` },
    { text: "Claims", href: `${baseAahUrl}claims/${queryStr}` },
    { text: "Learning Center", href: `${baseAahUrl}learning-center/${queryStr}` },
    { text: "About", href: `${baseAahUrl}about-us/${queryStr}` },
    { text: "Help", href: `${baseAahUrl}help/${queryStr}` },
  ];

  const homeHref = "https://connectbyamfam.com/" + partnerAddendum;
  const phoneNumber = includePhoneNumber ? "1-888-239-9953" : ""; // TODO: Eventually need to change the phone number based upon the partner, but for the PMI site, we've been asked to not show it at all, so includePhoneNumber will always be false for now.

  return (
    <div data-testid="af-header" className="af-header">
      <LogoBar partnerId={partnerId} homeHref={homeHref} phoneNumber={phoneNumber} />
      {hideMenu ? null : <MenuBar partnerId={partnerId} menuItems={menuItems} phoneNumber={phoneNumber} />}
      {banners && banners.alerts ? <BannerBar type="alert" messages={banners.alerts} /> : null}
      {banners && banners.messages ? <BannerBar type="banner" messages={banners.messages} /> : null}
    </div>
  );
}

export default Header;
