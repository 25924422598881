// @flow
import * as React from "react";

import logo from "../../../assets/images/connect-logo.png";
import costcoLogo from "../../../assets/images/costco-logo.png";
import ameripriseLogo from "../../../assets/images/ameriprise-logo.png";

type Props = {
  partnerId?: ?string,
  homeHref: string,
  phoneNumber: string,
};

function LogoBar(props: Props) {
  const { partnerId, phoneNumber } = props;

  return (
    <div data-testid="af-logobar" className="af-logobar">
      <div className="container px-0 pl-sm-2">
        <div className="col d-inline-flex justify-content-center justify-content-sm-between align-content-center w-100 my-3 px-sm-0">
          <div>
            <Logo {...props} />
            {phoneNumber ? <PhoneNumber {...props} /> : null}
          </div>
          {partnerId ? (
            <div className={"d-flex align-items-end ml-2"}>
              <PartnerLogo {...props} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function Logo(props: Props) {
  const { homeHref } = props;
  return (
    <span>
      <a href={homeHref}>
        <div>
          <img src={logo} className="af-logo" alt="CONNECT | Powered by American Family Insurance" />
        </div>
      </a>
    </span>
  );
}

function PartnerLogo(props: Props) {
  const { partnerId } = props;
  const partnerHref = partnerId === "costco" ? "https://www.costco.com" : partnerId === "advisor" ? "https://www.ameriprise.com" : "";
  const partnerLogo = partnerId === "costco" ? costcoLogo : partnerId === "advisor" ? ameripriseLogo : null;
  return (
    <span>
      {partnerHref ? (
        <a href={partnerHref} target="_blank" rel="noopener noreferrer">
          {partnerLogo ? <img src={partnerLogo} className="img-fluid af-partner" alt={partnerId} /> : null}
        </a>
      ) : null}
    </span>
  );
}

function PhoneNumber(props: Props) {
  const { phoneNumber } = props;
  return (
    <span className={"af-phone-span ml-0 ml-sm-3"}>
      <a href={"tel:" + phoneNumber}>
        <i aria-hidden="true" className="icon-call" />
        <span className={"ml-1"}>{phoneNumber}</span>
      </a>
    </span>
  );
}

export default LogoBar;
