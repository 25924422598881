//@flow
import * as React from "react";

import PhoneNumberInput from "./phoneNumberInput";

/**
 * Type for options in select and list fields.
 */
export type Option = {
  text: string,
  value: string | number | boolean | Object,
};

type Props = {
  formField: Object,
  formProps: Object,
  formikField: Object,
  formikMeta: Object,
  option?: Option,
  optionIndex?: number,
};

function FieldRenderer(props: Props) {
  const { formField, formProps, formikField, formikMeta, option, optionIndex } = props;
  switch (formField.type) {
    case "select": {
      return (
        <div className={"select-wrapper" + (formField.floatingLabel ? " af-floating-label-control" : "")}>
          <select id={formField.name} className={"form-control" + (formikMeta.touched && formikMeta.error ? " is-invalid" : "")} name={formField.name} {...formikField}>
            {formField.options
              ? formField.options.map((option, i) => {
                  return (
                    <option key={`option-${i}`} value={option.value}>
                      {option.text}
                    </option>
                  );
                })
              : null}
          </select>
        </div>
      );
    }

    case "radiobuttonlist":
    case "checkboxlist": {
      const type = formField.type === "checkboxlist" ? "checkbox" : "radio";
      const checked = option && formikMeta.value === option.value;
      return option && typeof optionIndex === "number" ? (
        <div key={`${formField.name}-${type}-${optionIndex}`} className={`custom-control ${formProps.inline || formField.inline ? "custom-control-inline " : ""}custom-${type}`}>
          {checked ? (
            <input
              {...formikField}
              className={"custom-control-input" + (formikMeta.touched && formikMeta.error ? " af-invalid-control" : "")}
              id={`${formField.name}-${type}-input-${optionIndex}`}
              name={formField.name}
              value={option.value}
              type={type}
              defaultChecked
            />
          ) : (
            <input
              {...formikField}
              className={"custom-control-input" + (formikMeta.touched && formikMeta.error ? " af-invalid-control" : "")}
              id={`${formField.name}-${type}-input-${optionIndex}`}
              name={formField.name}
              value={option.value}
              type={type}
            />
          )}
          <label className={"custom-control-label" + (formikMeta.touched && formikMeta.error ? " af-invalid" : "")} htmlFor={`${formField.name}-${type}-input-${optionIndex}`}>
            {option.text}
          </label>
        </div>
      ) : null;
    }

    case "tel": {
      return <PhoneNumberInput formField={formField} formikField={formikField} formikMeta={formikMeta} />;
    }

    case "zip": {
      // 'left arrow', 'up arrow', 'right arrow', 'down arrow',
      const arrowsKeyCodes = [37, 38, 39, 40];
      // 'numpad 0', 'numpad 1',  'numpad 2', 'numpad 3', 'numpad 4', 'numpad 5', 'numpad 6', 'numpad 7', 'numpad 8', 'numpad 9'
      const numPadNumberKeyCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
      return (
        <input
          id={formField.name}
          className={"form-control" + (formikMeta.touched && formikMeta.error ? " is-invalid" : "") + (formField.floatingLabel ? " af-floating-label-control" : "")}
          type={"text"}
          pattern={"[0-9]*"}
          placeholder={formField.placeholder}
          size={10}
          maxLength={5}
          onKeyDown={(e: KeyboardEvent) => {
            if (
              ((e.keyCode < 48 && !arrowsKeyCodes.includes(e.keyCode)) || (e.keyCode > 57 && !numPadNumberKeyCodes.includes(e.keyCode))) &&
              !(e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 46)
            ) {
              e.preventDefault();
            }
          }}
          {...formikField}
        />
      );
    }

    default: {
      return (
        <input
          id={formField.name}
          className={"form-control" + (formikMeta.touched && formikMeta.error ? " is-invalid" : "") + (formField.floatingLabel ? " af-floating-label-control" : "")}
          type={formField.type}
          placeholder={formField.placeholder}
          size={formField.size}
          maxLength={formField.maxLength}
          {...formikField}
        />
      );
    }
  }
}

export default FieldRenderer;
