// @flow
import * as React from "react";

import bbbImage from "../../assets/images/bbb-accredited.jpg";

type Props = {}; // Placeholder for future props, if we have any.

const bbbText =
  "A.M. Best, the leading independent rater of insurers in the country, has rated the companies within the CONNECT group “A” (Excellent), the third highest of their 15 ratings, for financial strength, stability and soundness of operating performance.";
const bbbHref = "http://www.bbb.org/wisconsin/business-reviews/insurance-companies/ameriprise-auto-home-insurance-in-de-pere-wi-9001116/#bbbonlineclick"; // TODO: Figure out if there's somewhere we'd like to link to from here.

function BbbBar(props: Props) {
  return (
    <div data-testid="af-bbb-bar" className="af-bbb-bar">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-9 col-md-10 text-center text-sm-left">{bbbText}</div>
          <div className="col-12 col-sm-3 col-md-2 mt-2 mt-sm-0 text-center">
            <a href={bbbHref} target="_blank" rel="noopener noreferrer">
              <img src={bbbImage} className="bbb-logo" alt="Better Business Bereau Accredited Business" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BbbBar;
