// @flow
import * as React from "react";

import MenuItem from "./menuItem";

type Props = {
  partnerId?: ?string,
  phoneNumber: string,
};

function StaticMenu(props: Props) {
  const { partnerId, phoneNumber } = props;

  const partnerLogin = partnerId === "advisor" ? "AMPF" : partnerId ? partnerId.toUpperCase() : "NEUTRAL";
  const loginUrl = process.env.REACT_APP_QUICK_URL ? process.env.REACT_APP_QUICK_URL + partnerLogin : "";

  return (
    <div data-testid="af-staticmenu" className={"af-staticmenu"}>
      {phoneNumber ? (
        <React.Fragment>
          <MenuItem text={phoneNumber} className={"af-phone-number"} icon="call" href={"tel:" + phoneNumber} />
          <MenuItem text="Call" className={"af-phone-number-xs"} icon="call" href={"tel:" + phoneNumber} />
        </React.Fragment>
      ) : null}
      <MenuItem text="My Policy" className={"af-policy"} icon="log-in" href={loginUrl} />
      <MenuItem text="Policy" className={"af-policy-xs"} icon="log-in" href={loginUrl} />
    </div>
  );
}

export default StaticMenu;
