// @flow
import * as React from "react";
import { useState, useEffect } from "react";

import track from "react-tracking";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import "./assets/styles/App.scss";

// import API from "./services/api";
import Page from "./controls/page";

import Maintenance from "./content/maintenance";
import UpdateEmailPrefs from "./content/updateEmailPrefs";
import CcpaForm from "./content/ccpaForm";
import CcpaSuccess from "./content/ccpaSuccess";
// import LandingPage from "./content/landingPage";

function useBannerMessages() {
  const [banners, setBanners] = useState({});

  function fetchBanners() {
    setBanners({ alerts: [], messages: [] });

    // TODO: Need to get the correct REST endpoint. Commenting this block out for now so we aren't constantly hitting a non-existent endpoint.
    // API.get("/bannermessages").then((result) => {
    //   let resultAlerts: Array<string>;
    //   let resultMessages: Array<string>;
    //   if (result.success || true) {
    //     //TODO: Remove the '|| true' here.
    //     // TODO: Need to understand what sort of data we'll get back from API call.
    //     // For now, we'll just mock something up.
    //     resultAlerts = ["Here is an alert message."];
    //     resultMessages = ["Here is a message.", "Here is another message."];
    //   }
    //   setData({ alerts: resultAlerts, banners: resultMessages });
    // });
  }
  useEffect(() => {
    fetchBanners();
  }, []);
  return [banners];
}

function App() {
  const [banners] = useBannerMessages();

  console.log("PrivacyManagementInterface Version 2.0");

  useEffect(() => {
    // On initial mount, print out our version and tag
    console.log("Version:", process.env.REACT_APP_VERSION || "Not Found");
    console.log("Tag:", process.env.REACT_APP_TAG || "Not Found");
  }, []);

  return (
    <div className="af-app" data-testid="af-app">
      <Router>
        <Switch>
          {/** Route to Maintenance page */}
          <Route path="/maintenance">
            <Page name="PMI | Maintenance" content={<Maintenance />} contentClass={"splash-content container"} hideMenu={true} hideSocial={true} banners={null} alerts={null} />
          </Route>
          {/* Route to OptOut page */}
          <Route path="/optout">
            <Page name="PMI | OptOut" content={<UpdateEmailPrefs />} banners={banners} />
          </Route>
          {/* Route to CCPA page */}
          <Route path="/privacyrequest">
            <Page name="PMI | privacyrequest" content={<CcpaForm />} banners={banners} />
          </Route>
          {/* Route to CCPA Success page */}
          <Route path="/privacysuccess">
            <Page name="PMI | privacysuccess" content={<CcpaSuccess />} banners={banners} />
          </Route>

          {/*
            Route base requests to a landing page.
            External links should point directly to the page they wish to display (optout, ccpa, etc.)
          */}
          <Route exact path="/">
            <Redirect to="/optout" />
          </Route>

          {/*
            If navigating to an unknown path, we'll redirect to the connectbyamfam site.
          */}
          <Route
            exact
            path="*"
            component={() => {
              window.location.href = "https://www.connectbyamfam.com/";
              return null;
            }}
          />
        </Switch>
      </Router>
    </div>
  );
}

/**
 * We're wrapping our app in a tracker so that we can dispatch any events that happen to the apppropriate analytics services.
 */
export default track(
  {},
  {
    dispatch: (data) => {
      console.log("dispatch:", data);
      if (window && window.digitalData) {
        if (data.page && window.digitalData.pageInfo) {
          window.digitalData.pageInfo.page = data.page;
        }
      }
    },
  }
)(App);
