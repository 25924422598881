// @flow
import * as React from "react";
import { Fragment, useState } from "react";

import MenuItem from "./menuItem";

type Props = {
  partnerId?: ?string,
  menuItems: Array<Object>,
};

function Menu(props: Props) {
  const { partnerId, menuItems } = props;

  const [expanded, setExpanded] = useState(false);

  const partnerAddendum = partnerId ? partnerId + "/" : "";
  const homeUrl = "https://connectbyamfam.com/" + partnerAddendum;

  return (
    <Fragment>
      <button
        data-testid="af-hamburger"
        className="af-hamburger"
        aria-expanded={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <span className="icon-menu" />
        <span>Menu</span>
      </button>
      <div
        data-testid="af-menu"
        className={"af-menu"}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <a data-testid="af-home" className={"af-home"} href={homeUrl}>
          <span className={"af-menuitem-text"}>Home</span>
        </a>
        {menuItems
          ? menuItems.map((item) => {
              return <MenuItem key={item.text} text={item.text} href={item.href} />;
            })
          : null}
      </div>
      {expanded ? (
        <div
          className={"af-menu-overlay"}
          onClick={() => {
            console.log("af-menu-overlay onClick");
            setExpanded(!expanded);
          }}
        />
      ) : null}
    </Fragment>
  );
}

export default Menu;
