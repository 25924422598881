//@flow
import * as React from "react";
import { Fragment, useState, useLayoutEffect } from "react";

type Props = {
  formField: Object,
  formikField: Object,
  formikMeta: Object,
};

function PhoneNumberInput(props: Props) {
  let inputRef;

  const { formField, formikField, formikMeta } = props;

  const id = `input-tel-${formikField.name}`;
  const displayValue = formatValue(formikField.value);

  const [caretPos, setCaretPos] = useState(-1);
  const [back, setBack] = useState(false);
  useLayoutEffect(() => {
    // Because we're changing the display value to include a mask in the display,
    // the caret position moves to the end of the mask each time the field is updated.
    // To counteract this, we'll reset the caret position to the position after the last known position.
    // We're doing this in useLayoutEffect so that this update happens synchronously before the visual rendering of the page.
    if (inputRef && formikField.value) {
      const newCaretPos = caretPos >= 0 ? caretPos : findLastDigit(formatValue(formikField.value));
      inputRef.setSelectionRange(newCaretPos, newCaretPos);
    }
  }, [formikField, inputRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <input
        {...formikField}
        ref={(ref) => (inputRef = ref)}
        id={id}
        className={"form-control" + (formikMeta.touched && formikMeta.error ? " is-invalid" : "") + (formField.floatingLabel ? " af-floating-label-control" : "")}
        type={"tel"}
        placeholder={formField.placeholder}
        value={displayValue ? displayValue : ""}
        onKeyDown={(e: KeyboardEvent) => {
          if (inputRef && !preventNonNumber(e)) {
            const key = e.keyCode || e.charCode;
            if (key === 8 || key === 46) {
              setBack(true);
              setCaretPos(inputRef.selectionStart - (key === 8 ? 1 : 0));
            } else {
              setBack(false);
            }
          }
        }}
        onChange={(e) => {
          handleChange(e, formikField, setCaretPos, back);
        }}
        onBlur={(e) => {
          handleBlur(e, formikField);
        }}
      />
    </Fragment>
  );
}

function handleChange(event: SyntheticInputEvent<HTMLInputElement>, formikField: Object, setCaretPos: Function, back: boolean) {
  // First, clean the value and set it in the form's data.
  event.target.value = cleanValue(event.target.value);

  // We want to set the new caret position to the position after the most recent change.
  // To do this, we'll compare the current value to the last known value.
  if (!back && formikField.value) {
    const newValue = formatValue(event.target.value);
    const lastKnown = formatValue(formikField.value);
    const firstDiff = findFirstDiff(newValue, lastKnown);
    setCaretPos(firstDiff + 1);
  }

  formikField.onChange(event);
}

function handleBlur(event: SyntheticInputEvent<HTMLInputElement>, formikField: Object) {
  // On blur, clean the current value and set it in the form's data.
  event.target.value = cleanValue(event.target.value);
  formikField.onBlur(event);
}

function formatValue(value?: ?string) {
  if (value) {
    const cleaned = value.replace(/\D/g, "");
    const padded = cleaned.padEnd(10, "_");
    return "(" + padded.substring(0, 3) + ") " + padded.substring(3, 6) + "-" + padded.substring(6, 10);
  } else {
    return null;
  }
}

function cleanValue(value: ?string) {
  if (value) {
    const cleaned = value.replace(/(\(|\)|_|-|\s)/g, "").substring(0, 10);
    return cleaned;
  } else {
    return "";
  }
}

function findLastDigit(value: ?string) {
  if (value) {
    for (let i = value.length - 1; i > 0; i--) {
      const valueChar = value[i];
      if (!isNaN(parseInt(valueChar))) {
        return i + 1;
      }
    }
    return 0;
  } else {
    return 0;
  }
}

function findFirstDiff(a: ?string, b: ?string) {
  if (!a || !b) {
    return -1;
  }
  if (a === b) {
    return a.length;
  }
  let i = 0;
  while (a[i] === b[i]) {
    i++;
  }
  return i;
}

// 'left arrow', 'up arrow', 'right arrow', 'down arrow',
const arrowsKeyCodes = [37, 38, 39, 40];
// 'numpad 0', 'numpad 1',  'numpad 2', 'numpad 3', 'numpad 4', 'numpad 5', 'numpad 6', 'numpad 7', 'numpad 8', 'numpad 9'
const numPadNumberKeyCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

function preventNonNumber(e: KeyboardEvent) {
  // allow only [0-9] number, numpad number, arrow,  BackSpace, Tab
  if (((e.keyCode < 48 && !arrowsKeyCodes.includes(e.keyCode)) || (e.keyCode > 57 && !numPadNumberKeyCodes.includes(e.keyCode))) && !(e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 46)) {
    e.preventDefault();
    return true;
  } else {
    return false;
  }
}

export default PhoneNumberInput;
