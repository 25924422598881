//@flow
import * as React from "react";

type Props = {
  loading: boolean,
  className?: string,
};

function Loading(props: Props) {
  const propsClassName = props.className ? ` ${props.className}` : "";
  if (props.loading) {
    return (
      <div data-testid={"af-loading"} className={`spinner-outer-border${propsClassName}`}>
        <div className={"spinner-border"}>
          <div className={"spinner-inner-border"} />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Loading;
