//@flow
import * as React from "react";

type Link = {
  text: string,
  href: string,
};

type LinkGroup = {
  header?: Link,
  links?: Array<Link>,
  groups?: Array<LinkGroup>,
};

type Props = {
  linkGroups: Array<LinkGroup>,
};

function LinkBar(props: Props) {
  return (
    <div data-testid="af-link-bar" className="af-link-bar">
      <div className="container">
        <div className="row af-link-groups">
          {props.linkGroups
            ? props.linkGroups.map((linkGroup, i) => {
                return <LinkGroupRenderer {...linkGroup} key={`linkgroup-${i}`} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
}

function LinkGroupRenderer(linkGroup: LinkGroup) {
  return (
    <div data-testid={"af-link-group"} className={"af-link-group"}>
      {linkGroup.header ? (
        <h4>
          <LinkRenderer {...linkGroup.header} />
        </h4>
      ) : null}
      {linkGroup.links ? (
        <ul>
          {linkGroup.links.map((link, i) => {
            return (
              <li key={`link-${i}`}>
                <LinkRenderer {...link} />
              </li>
            );
          })}
        </ul>
      ) : null}
      {linkGroup.groups
        ? linkGroup.groups.map((group, i) => {
            return <LinkGroupRenderer {...group} key={`linkgroup-${i}`} />;
          })
        : null}
    </div>
  );
}

function LinkRenderer(link: Link) {
  return (
    <a data-testid={"af-link"} href={link.href}>
      {link.text}
    </a>
  );
}

export default LinkBar;
