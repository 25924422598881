// @flow
import * as React from "react";
import { Fragment } from "react";

import { withRouter } from "react-router-dom";
import track from "react-tracking";

import Header, { type Banners } from "./header/header";
import Footer from "./footer/footer";

type Props = {
  name: string,
  content: React.Node,
  contentClass?: string,
  banners?: Banners,
  includePhoneNumber?: boolean,
  hideMenu?: boolean,
  hideSocial?: boolean,
  location: Object, // location is injected into props by the HOC withRouter
  history: Object, // history is injected into props by the HOC withRouter
};

function Page(props: Props) {
  const { location, includePhoneNumber, hideMenu, hideSocial, banners, content, contentClass } = props;

  const [partnerId, setPartnerId] = React.useState(undefined);

  React.useLayoutEffect(() => {
    if (location.search) {
      const query = parseQuery(location.search);
      setPartnerId(query.partnerid);
    }
  }, [location]);

  return (
    <div data-testid="af-page">
      <Header partnerId={partnerId} includePhoneNumber={includePhoneNumber} hideMenu={hideMenu} banners={banners} />
      <div className={contentClass || "af-content container"}>{content}</div>
      <Footer partnerId={partnerId} hideSocial={hideSocial} />
    </div>
  );
}

function parseQuery(queryString: string): Object {
  var query = {};
  var pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0]).toLowerCase()] = decodeURIComponent(pair[1] || "").toLowerCase();
  }
  return query;
}

Page.defaultProps = {
  content: <Fragment>No content provided for this page</Fragment>,
};

export default track(
  (props) => {
    return { page: props.name, event: "page-ready" };
  },
  { dispatchOnMount: true }
)(withRouter(Page));
