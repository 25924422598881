// @flow
import * as React from "react";
import { useState, useRef, useEffect, Fragment } from "react";

import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import * as Yup from "yup";
import track, { useTracking } from "react-tracking";

import FieldRenderer from "./fieldRenderer";
import { type Option } from "./fieldRenderer";

import Loading from "../loading/loading";

/**
 * Type for form fields.
 *
 * @name              Name for the field. This will be used as the key in the key-value pair returned on submit.
 * @type              Type of the field.
 *                      * string -            A basic text field.
 *                      * number -            A numeric text field.
 *                      * date -              A date field.
 *                      * tel -               A phone number field.
 *                      * zip -               A 5-digit zip code field.
 *                      * select -            A select field.
 *                                            This type requires that you specify options for the field.
 *                      * checkbox -          A basic checkbox field.
 *                                            This will set a true/false value in the data returned on submit.
 *                      * checkboxlist -      A list of checkboxes.
 *                                            This will set an array value in the data returned on submit containing a list of the values of each of the checkboxes selected.
 *                                            This field type requires a list of options to be included with the field.
 *                      * radiobuttonlist -   A list of radio buttons.
 *                                            The value of the selected radio button will be included in the data returned on submit.
 *                                            This field type requires a list of options to be included with the field.
 *                      * group -             A group of fields.
 *                                            This field type requires a list of other fields to be included in with the field.
 *                                            Rendering of the fields in the group is controlled by the group* attributes of this field (groupStyle, groupRows, groupColumns).
 *                      * label -             Displays a label with the initial value of the field. No input is displayed.
 *                      * custom -            Displays a custom rendered field. Use with the customRenderer attribute.
 * @label             The display label for the field.
 *                    If you have selected the inline option for the form, the label will not be shown.
 *                    If the field is of type 'group', the label will be used as the header for the group of fields.
 * @floatingLabel     Indicates whether to float the label on top of the field.
 *                    Useful to make forms more compact.
 * @placeholder       Placeholder text for the field.
 *                    Only used with fields of type 'string' and 'number'.
 * @maxLength         Maximum length of the field.
 *                    Only used with fields of type 'string' and 'number'.
 * @size              Width of the field.
 *                    Only used with fields of type 'string', 'number', and 'select'.
 * @validation        Yup validation rules for the field.
 *                    Fields are validated upon attempted submission of the form.
 * @initialvalue      Initial value of the field.
 * @options           Options for the field.
 *                    Used by fields of type 'select', 'checkboxlist', and 'radiobuttonlist'.
 *                    This can be a static array for any of the above types.
 *                    For a select field, this can also be a function which returns a Promise which returns an array of Options.
 * @boundFields       A list of one or more field names to which this field is bound.
 *                    Only used by fields of type 'select' using an options function.
 *                    When the value of a field in the boundFields list changes, the 'select' control will reload its options list.
 * @inline            Indicates whether the options for the field should be displayed inline.
 *                    This applies to fields of type 'checkboxlist' and 'radiobuttonlist'.
 * @fields            List of fields to be displayed for a group.
 *                    Applies only to fields of type 'group'.
 * @groupStyle        The layout style for a 'group' field.
 *                      * list -    Display the fields in the group in a vertical list. This is the default behavoior if no style is specified.
 *                      * grid -    Display the fields in a grid with the number of columns specified for 'groupColumns'.
 * @groupColumns      The number of columns for a 'group' field with a 'groupStyle' of 'grid'.
 *                    If not defined, the number of columns will default to 1, which will render identically to a 'group' with 'groupStyle' of 'list'.
 *                    If you need more fine-grained control of field widths, use in conjuntion with the columnStart and columnEnd properties to define your grid layout.
 * @columnStart       Use this to specify the column at which this field should start within a grid.
 *                    This will be ignored if the field is not part of a group or the group's groupStyle is not 'grid'.
 * @columnEnd         Use this to specify the column at which this field should end within a grid.
 *                    This will be ignored if the field is not part of a group or the group's groupStyle is not 'grid'.
 * @visibilityCheck   A function that will be passed the current values in the form and must return a boolean value indicating whether the field should be visible.
 *                    This allows for conditional visiblity of form fields based upon other selections in the form.
 *                    If no visibilityCheck function is supplied, the field will always be visible.
 * @customRenderer    Used to render a custom field in the form.
 *                    The field, form props, formik field, formik metadata for the field, errors for the field, and isvalid state for the field will be passed through to the custom renderer.
 *                    The customerRenderer function must return a React.Node (i.e. JSX).
 */
export type Field = {
  name: string,
  type: "text" | "number" | "date" | "tel" | "zip" | "select" | "checkbox" | "checkboxlist" | "radiobuttonlist" | "group" | "label" | "custom",
  label?: string,
  floatingLabel?: boolean,
  placeholder?: string,
  maxLength?: number,
  size?: number,
  validation: Object,
  initialValue?: string | number | boolean,
  options?: Array<Option> | ((Object) => Promise<Array<Option>>),
  boundFields?: Array<string>,
  inline?: boolean,
  fields?: Array<Field>,
  groupStyle?: "list" | "grid",
  groupColumns?: number,
  columnStart?: number,
  columnEnd?: number,
  visibilityCheck?: (Object) => boolean,
  customRenderer?: (Object, Object, Object, Object, Object, boolean) => React.Node,
};

/**
 * Type for form submit results.
 */
type Result = {
  success: boolean,
  message?: string,
};

/**
 * Props for the Form.
 */
type Props = {
  "data-testid": string,
  fields: Array<Field>, // The list of fields to be displayed in the form.
  submitText?: string, // The text to be displayed on the submit button for the form. If not specified, the default "Submit" will be used.
  onSubmit?: (Object) => Promise<Result>, // A function to be run when the form is submitted.
  inline?: boolean, // Indicates that the form should be rendered in an inline fashion.
  disableSubmitWhenInvalid?: boolean, // Indicates whether the submit button should be disabled when the data entered into the form is invalid.
  isStep: boolean, // Indicates whether this form is a step in a multi-step form.
};

function Form(props: Props) {
  const tracking = useTracking();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  // Track the mount state of the component so that we aren't attempting to change anything once the form has been unmounted.
  const componentIsMounted = useRef(true);
  useEffect(() => {
    // Run this cleanup on unmount to update the component's mount state.
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this will only run on mount, and the cleanup will only run on unmount.

  // Gather information from the form's fields regarding validation and initial values.
  const getValuesFromFields = (fields: Array<Field>, validationObj: Object, initialValuesObj: Object) => {
    fields.forEach((field) => {
      if (field.type === "group" && field.fields) {
        getValuesFromFields(field.fields, validationObj, initialValuesObj);
      } else if (field.type !== "group") {
        validationObj[field.name] = field.validation;
        initialValuesObj[field.name] = field.initialValue || typeof field.initialValue === "boolean" ? field.initialValue : field.type === "number" ? 0 : "";
      }
    });
  };

  const validation = {}; // An object that will contain a validator entry for each field in our form.
  const initialValues = {}; // An object that will contain an initial value entry for each field in our form.
  if (props.fields) {
    getValuesFromFields(props.fields, validation, initialValues);
  }

  // Create the validation schema for our form based upon the field validation gathered above.
  const validationSchema = Yup.object(validation);

  // The function called when the form is submitted.
  const submitForm = (values, { setSubmitting }) => {
    //console.log("submitting:", values);
    tracking.trackEvent({ event: "submit-attempt" });
    if (props.onSubmit) {
      props.onSubmit(values).then((result) => {
        if (componentIsMounted.current) {
          // Often when we do something in a form we'll proceed immediately to another page
          // or make some other change to the DOM which causes our form to become unmounted.
          // We only need to update state when our form is still mounted.
          setSuccess(result.success);
          setMessage(result.message);
          setSubmitting(false);
        }
      });
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {(formik) => {
        //console.log("touched:", formik.touched);
        //console.log("values:", formik.values);
        //console.log("errors: ", formik.errors);
        // We want to disable the submit button while the form is submitting (formik.isSubmitting).
        // We'll also disable the submit button while the data in the form is invalid and disableSubmitWhenInvalid is set (!formik.isValid).
        // We'll also disable the submit button if no data has been entered in the form and disableSubmitWhenInvalid is set (!formik.dirty).
        const disabled = formik.isSubmitting || (props.disableSubmitWhenInvalid && (!formik.isValid || !formik.dirty)) ? true : false;
        return (
          <FormikForm data-testid={props["data-testid"]}>
            {message && props.inline ? <div className={"alert " + (success ? "alert-success" : "alert-danger")}>{message}</div> : null}
            <div className={props.inline ? "flex spaced-form-grow-wrap" : ""}>
              {props.fields ? renderFields(props.fields, props, formik, disabled) : null}
              {message && !props.inline ? <div className={"alert " + (success ? "alert-success" : "alert-danger")}>{message}</div> : null}
              <div className={props.isStep ? "af-step-buttons" : ""}>
                <button className={"btn btn-primary mb-2" + (hasErrors(formik) ? " btn-invalid" : "")} type="submit" disabled={disabled}>
                  {props.submitText ? props.submitText : "Submit"}
                </button>
                <Loading className={"ml-3"} loading={formik.isSubmitting} />
              </div>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
}

/**
 * Checks whether the form has any visible errors.
 */
function hasErrors(formik: Object) {
  let errorFound = false;
  if (!formik.isValid || !formik.dirty) {
    Object.keys(formik.touched).forEach((key) => {
      if (formik.errors[key]) {
        errorFound = true;
      }
    });
  }
  return errorFound;
}

/**
 * Renders fields for the form and recursively renders fields for any groups in the form.
 */
function renderFields(fields: Array<Field>, props: Props, formik: Object, disabled: boolean, parent?: Field) {
  const layoutClass = parent && parent.groupStyle === "grid" ? "af-grid" : "";
  const layoutColumns = parent && parent.groupColumns ? parent.groupColumns : 1;
  let lastColumn = 1;
  let lastRow = 1;
  return (
    <FragmentOrDiv className={layoutClass}>
      {fields.map((formField) => {
        const visible = formField.visibilityCheck ? formField.visibilityCheck(formik.values) : true;
        if (visible) {
          const gridColumnStart = formField.columnStart ? formField.columnStart : lastColumn;
          const gridColumnEnd = formField.columnEnd ? formField.columnEnd : lastColumn;
          const layoutItemStyle = layoutClass === "af-grid" ? { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, gridRowStart: lastRow } : {};
          if (gridColumnEnd >= layoutColumns) {
            lastColumn = 1;
            lastRow++;
          } else {
            lastColumn = lastColumn + (formField.columnEnd && formField.columnStart ? formField.columnEnd + 1 - formField.columnStart : 1);
          }
          if (formField.customRenderer) {
            return (
              <FormikField key={`field-${formField.name}`} name={formField.name}>
                {({ field, form: { errors, isValid }, meta }) => {
                  // TODO: Need to actually test out custom formatting and figure out how to handle errors.
                  return <div style={layoutItemStyle}>{formField.customRenderer ? formField.customRenderer(formField, props, field, meta, errors, isValid) : null}</div>;
                }}
              </FormikField>
            );
          } else if (formField.type === "group") {
            return (
              <div key={`fieldgroup-${formField.name}`} style={layoutItemStyle}>
                {formField.label ? <div className={"af-groupheader"}>{formField.label}</div> : null}
                {formField.fields ? renderFields(formField.fields, props, formik, disabled, formField) : null}
              </div>
            );
          } else if (formField.type === "checkboxlist" || formField.type === "radiobuttonlist") {
            // We need to handle radiobuttonlists and checkboxlists differently because the form needs them to be grouped in order to function as a value array.
            const type = formField.type === "checkboxlist" ? "checkbox" : "radio";
            const error = formik.errors && formik.errors[formField.name] && formik.touched && formik.touched[formField.name] ? formik.errors[formField.name] : "";
            return (
              <div key={`${formField.name}-div`} style={layoutItemStyle}>
                {!props.inline ? (
                  <label key={`${formField.name}-label`} htmlFor={formField.name}>
                    {formField.label}
                  </label>
                ) : null}
                <div className={"form-group"} role="group" aria-labelledby={`${type}-group`}>
                  {formField.options && Array.isArray(formField.options)
                    ? formField.options.map((option, i) => {
                        return (
                          <FormikField key={`field-${formField.name}-${i}`} name={formField.name}>
                            {({ field, form: { isValid }, meta }) => {
                              return <FieldRenderer formField={formField} formProps={props} formikField={field} formikMeta={meta} formik={formik} option={option} optionIndex={i} />;
                            }}
                          </FormikField>
                        );
                      })
                    : null}
                  <div className="mt-1 af-invalid-feedback">{error}</div>
                </div>
              </div>
            );
          } else {
            const error = formik.errors && formik.errors[formField.name] && formik.touched && formik.touched[formField.name] ? formik.errors[formField.name] : "";
            return (
              <FormikField key={`field-${formField.name}`} name={formField.name}>
                {({ field, form: { isValid }, meta }) => {
                  return (
                    <div style={layoutItemStyle}>
                      <div className={"form-group" + (formField.floatingLabel ? " af-floating-label-group" : "") + (props.inline ? " inline" : "")}>
                        {!props.inline && !formField.floatingLabel ? <label htmlFor={formField.name}>{formField.label}</label> : null}
                        <FieldRenderer formField={formField} formProps={props} formikField={field} formikMeta={meta} formik={formik} />
                        {formField.floatingLabel ? (
                          <div className={"af-floating-div"}>
                            <label className={formField.floatingLabel ? (field.value ? "af-floating-label" : "af-floating-placeholder") : ""} htmlFor={formField.name}>
                              {formField.label}
                            </label>
                            <div className={"mb-1 af-invalid-feedback" + (formField.floatingLabel ? " af-floating-label-control" : "")}>{error}</div>
                          </div>
                        ) : (
                          <div className={"mb-1 af-invalid-feedback" + (formField.floatingLabel ? " af-floating-label-control" : "")}>{error}</div>
                        )}
                      </div>
                    </div>
                  );
                }}
              </FormikField>
            );
          }
        } else {
          return null;
        }
      })}
    </FragmentOrDiv>
  );
}

function FragmentOrDiv(props: Object) {
  if (props.className) {
    return <div className={props.className}>{props.children}</div>;
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
}

export default track()(Form);
