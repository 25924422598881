// @flow
import * as React from "react";

import BbbBar from "./bbbBar";
import QuoteBar from "./quoteBar";
import LinkBar from "./linkBar";
import AboutBar from "./aboutBar";
import CopyrightBar from "./copyrightBar";

type Props = {
  partnerId?: ?string,
  includeLinks?: boolean,
  hideSocial?: boolean,
};

function Footer(props: Props) {
  const { partnerId, includeLinks, hideSocial } = props;

  const partnerAddendum = partnerId ? partnerId + "/" : "";
  const baseAahUrl = process.env.REACT_APP_AAH_URL ? process.env.REACT_APP_AAH_URL + partnerAddendum : "";
  const baseQuickUrl = process.env.REACT_APP_QUICK_URL ? process.env.REACT_APP_QUICK_URL + partnerAddendum : "";
  const queryStr = window.location.search ? window.location.search : "";
  const linkGroups = [
    {
      header: { text: "Insurance Choices", href: `${baseAahUrl}insurance-choices${queryStr}` },
      links: [
        { text: "Auto Insurance", href: `${baseAahUrl}insurance-choices/auto${queryStr}` },
        { text: "Home & Condo Insurance", href: `${baseAahUrl}insurance-choices/home-condo${queryStr}` },
        { text: "Renters Insurance", href: `${baseAahUrl}insurance-choices/renters${queryStr}` },
        { text: "Umbrella Insurance", href: `${baseAahUrl}insurance-choices/umbrella${queryStr}` },
        { text: "Specialty Insurance", href: `${baseAahUrl}insurance-choices/specialty${queryStr}` },
      ],
    },
    {
      header: { text: "Claims", href: `${baseAahUrl}claims${queryStr}` },
      links: [
        { text: "Auto Claims", href: `${baseAahUrl}claims/auto-claims${queryStr}` },
        { text: "HomeOwners Claims", href: `${baseAahUrl}claims/homeowners-claims${queryStr}` },
        { text: "Common Claims Questions", href: `${baseAahUrl}claims/common-claims-questions${queryStr}` },
        { text: "Types of Claims", href: `${baseAahUrl}claims/types-of-claims${queryStr}` },
        { text: "Ways to Report a Claim", href: `${baseAahUrl}claims/report-insurance-claim${queryStr}` },
      ],
    },
    {
      header: { text: "Learning Center", href: `${baseAahUrl}learning-center${queryStr}` },
      links: [
        { text: "Auto", href: `${baseAahUrl}learning-center/auto${queryStr}` },
        { text: "Home & Renters", href: `${baseAahUrl}learning-center/home-and-renters${queryStr}` },
        { text: "Disaster Preparedness", href: `${baseAahUrl}learning-center/disaster-preparedness${queryStr}` },
        { text: "Understanding Insurance", href: `${baseAahUrl}learning-center/understanding-insurance${queryStr}` },
      ],
    },
    {
      header: { text: "About", href: `${baseAahUrl}about-us/${queryStr}` },
      links: [
        { text: "Benefits and Discounts", href: `${baseAahUrl}about-us/benefits-and-discounts${queryStr}` },
        { text: "Customer Testimonials", href: `${baseAahUrl}about-us/customer-testimonials-and-reviews${queryStr}` },
      ],
    },
    {
      header: { text: "Help", href: `${baseAahUrl}help${queryStr}` },
      links: [
        { text: "Contact Us", href: `${baseAahUrl}help/contact-us${queryStr}` },
        { text: "Frequently Asked Questions", href: `${baseAahUrl}help/faq${queryStr}` },
      ],
    },
    {
      header: { text: "Quick Links", href: `${baseQuickUrl}` },
      links: [
        { text: "Make a Payment", href: `${baseQuickUrl}neutral/verify?next=payment` },
        { text: "Update Credit Card", href: `${baseQuickUrl}neutral/verify?next=credit` },
        { text: "Update Bank Account", href: `${baseQuickUrl}neutral/verify?next=bankaccount` },
        { text: "Request Declaration", href: `${baseQuickUrl}neutral/verify?next=documents` },
        { text: "Request Auto ID Card", href: `${baseQuickUrl}neutral/verify?next=idcards` },
        { text: "Request Policy Book", href: `${baseQuickUrl}neutral/verify?next=policybooks` },
      ],
    },
  ];

  return (
    <div data-testid="af-footer" className="af-footer">
      {includeLinks ? (
        <React.Fragment>
          <BbbBar />
          <QuoteBar />
          <LinkBar linkGroups={linkGroups} />
        </React.Fragment>
      ) : null}
      <AboutBar partnerId={partnerId} hideSocial={hideSocial} />
      <CopyrightBar partnerId={partnerId} />
    </div>
  );
}

export default Footer;
