// @flow
import * as React from "react";

import Menu from "./menu";
import StaticMenu from "./staticMenu";

type Props = {
  partnerId?: ?string,
  menuItems: Array<Object>,
  phoneNumber: string,
};

function MenuBar(props: Props) {
  const { partnerId, phoneNumber } = props;

  return (
    <div data-testid="af-menubar" className="af-menubar">
      <div className="container justify-space-between">
        <div className="row">
          <div className="col-6 col-md-9">
            <Menu {...props} />
          </div>
          <div className="col-6 col-md-3 text-right pr-0">
            <StaticMenu partnerId={partnerId} phoneNumber={phoneNumber} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuBar;
