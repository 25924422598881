//@flow
import axios from "axios";
import https from "https";

const httpsAgent = new https.Agent({
  minVersion: "TLSv1.2",
});

/**
 * Type definition for API responses.
 * All API requests will return success or failure.
 * API requests should always return a message on failure.
 * API requests which retrieve data will return that data in a data object.
 */
type ApiResponse = {
  success: boolean,
  message?: string,
  data?: Object,
};

/**
 * Defines the base API URL.
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

// TODO: We'll need to do something here to handle credentials on our API calls when we get to something that requires that.

/**
 * Handles error responses.
 *
 * @param {*} e
 */
const handleError = (e): ApiResponse => {
  console.error(e);
  console.error(e.response);
  const success = false;
  const message = e.response && e.response.data && e.response.data.Message ? e.response.data.Message : e.message;
  return { success, message };
};

class API {
  /**
   * Submits a GET request to the specified endpoint.
   *
   * @param {*} endpoint
   */
  static async get(endpoint: string): Promise<ApiResponse> {
    return await api
      .get(endpoint, { withCredentials: true, httpsAgent })
      .then((result) => {
        const resultData = result.data;
        const success = resultData.success;
        const message = resultData.message;
        const data = {}; // TODO: Need to get the expected format for returned data and pass that data through in our ApiResponse.
        return { success, message, data };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  /**
   * Submits a POST request to the specified endpoint with the supplied data.
   *
   * @param {*} endpoint
   * @param {*} data
   */
  static async post(endpoint: string, data: Object): Promise<ApiResponse> {
    return await api
      .post(endpoint, data, { withCredentials: true, httpsAgent })
      .then((result) => {
        const data = result.data;
        const success = result.status === 200 ? true : false;
        const message = data && data.message ? data.message : "";
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  /**
   * Submits a PUT request to the specified endpoint with the supplied data.
   *
   * @param {*} endpoint
   * @param {*} data
   */
  static async put(endpoint: string, data: Object): Promise<ApiResponse> {
    return await api
      .post(endpoint, data, { withCredentials: true, httpsAgent })
      .then((result) => {
        const data = result.data;
        const success = data.success;
        const message = data.message;
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }

  /**
   * Submits a DELETE request to the specified endpoint.
   * NOTE: This cannot be named 'delete' because it is a reserved word in JavaScript.
   *
   * @param {*} endpoint
   */
  static async delete(endpoint: string): Promise<ApiResponse> {
    return await api
      .delete(endpoint, { withCredentials: true, httpsAgent })
      .then((result) => {
        const data = result.data;
        const success = data.success;
        const message = data.message;
        return { success, message };
      })
      .catch((e) => {
        return handleError(e);
      });
  }
}

export default API;
