import * as React from "react";
import { useState } from "react";

import * as Yup from "yup";

import Form from "../controls/form/form";
import API from "../services/api";

function UpdateEmailPrefs() {
  const [success, setSuccess] = useState(false);
  const [address, setAddress] = useState("");
  const [header, setHeader] = useState("Want to unsubscribe?");
  return (
    <>
      <h1>{header}</h1>
      {success && address ? (
        <div>
          {/** Do we want a link to go someplace else once this is done? */}
          We’ve removed your email address {address} from our marketing email list. Please note that you will still receive other important policy communications via email.
        </div>
      ) : (
        <>
          <div className="mb-2">Please enter your email address and we’ll remove you from our marketing email list.</div>
          <Form
            data-testid="af-updateemailprefs"
            fields={[
              {
                name: "EmailAddress",
                type: "text",
                label: "Email Address",
                placeholder: "name@example.com",
                validation: Yup.string().email("Invalid email address. Please try again.").required("You must enter an email address."),
              },
            ]}
            onSubmit={(values) => {
              return new Promise((resolve) => {
                values["Action"] = 65; // Action 65 indicates an Add for the optout API
                API.post("Api.Privacy.Process/privacy/optout", values).then((result) => {
                  setSuccess(result.success ? true : false);
                  setAddress(result.success ? values["EmailAddress"] : "");
                  setHeader(result.success ? "You’ve successfully unsubscribed" : "");
                  resolve(result);
                });
              });
            }}
          />
        </>
      )}
    </>
  );
}

export default UpdateEmailPrefs;
