//@flow
import * as React from "react";

type Props = {}; // Placeholder for any future props should they arise.

function CcpaSuccess(props: Props) {
  return <div>Success! We've received your request and it's currently being processed. A member of our team will contact you if any additional information is needed. Thank you!</div>;
}

export default CcpaSuccess;
